"use client";

import React, { useRef } from "react";
import {
  Box,
  Stack,
  Text,
  Image,
  Flex,
  VStack,
  Button,
  Heading,
  SimpleGrid,
  StackDivider,
  useColorModeValue,
  Link,
  ChakraProvider,
  Divider,
  IconButton,
} from "@chakra-ui/react";
import { FaChevronUp } from "react-icons/fa";

import avatar from "../../components/data/img/maria.jpg";
import pic1 from "../../components/data/img/teampage/MZ_Picture1.jpg";
import pic2 from "../../components/data/img/teampage/MZ_Picture2.png";
import pic3 from "../../components/data/img/teampage/MZ_Picture3.jpg";
import pic4 from "../../components/data/img/teampage/MZ_Picture4.jpg";

export default function MariaTeamPage() {
  // Refs for scrolling
  const bioRef = useRef(null);
  const projectsRef = useRef(null);
  const projectDetailsRef2 = useRef(null);
  const projectDetailsRef3 = useRef(null);
  const projectDetailsRef4 = useRef(null);
  const menuRef = useRef(null);

  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <ChakraProvider>
      {/* A Box that holds the entire page (so everything scrolls as one) */}
          <Box px={4} py={6}>
              {/* Flex container: 2 columns on large screens, 1 column on base screens */}
              <Flex direction={{ base: "column", lg: "row" }} gap={6}>
                  {/* LEFT COLUMN */}
                  <Box
                      w={{ base: "100%", lg: "30%" }}
                      p={4}
                      bg={useColorModeValue("gray.50", "gray.800")}
                      ref={menuRef}
                  >
                      <Stack spacing={6}>
                          <Flex>
                              <Image
                                  rounded={"md"}
                                  alt={"Avatar"}
                                  src={avatar}
                                  fit={"contain"}
                                  align={"center"}
                                  w={"100%"}
                                  h={{ base: "250px", sm: "300px", lg: "400px" }}
                              />
                          </Flex>

                          <VStack spacing={4} >                              
                              <Button
                                  onClick={() => scrollToSection(bioRef)}
                                  colorScheme="blue"
                                  variant="link"
                                  opacity={0.9}
                              >
                                  About
                              </Button>
                              <Button
                                  onClick={() => scrollToSection(projectsRef)}
                                  colorScheme="blue"
                                  variant="link"
                                  opacity={0.9}
                              >
                                  Projects
                              </Button>
                              {/* Nested VStack for the specific project links */}
                              <VStack  spacing={2}>
                                  <Button
                                      onClick={() => scrollToSection(projectDetailsRef2)}
                                      colorScheme="blue"
                                      variant="link"
                                      opacity={0.9}
                                      size={"sm"}
                                  >
                                      Woody Encroachment and Fire Management
                                  </Button>
                                  <Button
                                      onClick={() => scrollToSection(projectDetailsRef3)}
                                      colorScheme="blue"
                                      variant="link"
                                      opacity={0.9}
                                      size={"sm"}
                                  >
                                      Prescribed Fire Mapping in Central Florida
                                  </Button>
                                  <Button
                                      onClick={() => scrollToSection(projectDetailsRef4)}
                                      colorScheme="blue"
                                      variant="link"
                                      opacity={0.9}
                                      size={"sm"}
                                  >
                                      Wildfires and Human Conflict
                                  </Button>
                              </VStack>
                          </VStack>

                      </Stack>
                  </Box>

          {/* RIGHT COLUMN */}
          <Box w={{ base: "100%", lg: "70%" }} p={4}>
            <Stack spacing={6}>
              {/* "About" section */}
              <Box as={"header"} ref={bioRef}>
                <Heading
                  lineHeight={1.1}
                  fontWeight={600}
                  fontSize={{ base: "2xl", sm: "4xl", lg: "5xl" }}
                >
                  Maria Zubkova
                </Heading>
                <Text
                  color={useColorModeValue("gray.900", "gray.400")}
                  fontWeight={300}
                  fontSize={"2xl"}
                  mb={6}
                >
                  Assistant Research Professor
                </Text>
                <Text
                  fontSize={{ base: "16px", lg: "20px" }}
                  color={useColorModeValue("yellow.500", "yellow.300")}
                  fontWeight={"500"}
                  textTransform={"uppercase"}
                  
                >
                  About
                </Text>
              </Box>

              <Stack
                spacing={6}
                direction={"column"}
                divider={
                  <StackDivider
                    borderColor={useColorModeValue("gray.200", "gray.600")}
                  />
                }
              >
                <VStack spacing={6}>
                  <Text fontSize={"md"}>
                    I am an Assistant Research Professor at the Department of
                    Geographical Sciences at the University of Maryland, College
                    Park. My research focuses on mapping various aspects of fire
                    activity based on remote-sensed data and improving our
                    understanding of the complex interaction between wildfires,
                    climate, biodiversity, and humans across scales. The goal of
                    my most recent research is to promote the use of beneficial
                    fires and enhance ecosystem resilience to fire since, in
                    recent years, catastrophic fires have led to the loss of
                    billions of dollars, lives, and undesirable ecological and
                    environmental consequences. I received my PhD in Natural
                    Resources with an emphasis on Remote Sensing and
                    Geographical Information Sciences from the University of
                    Idaho, my MS in Geoinformation in Environmental Management
                    from the Mediterranean Agronomic Institute of Chania (MAICh)
                    in Greece, and a BA+MS in Landscape Design from the Russian
                    State Agrarian University, Moscow, Russia.
                  </Text>
                  <Text fontSize={"md"}>
                    I am a Board member of the Society for Conservation GIS{" "}
                    <Link color="blue.500" href="https://www.scgis.org/">
                      SCGIS
                    </Link>{" "}
                    and the Chair of the SCGIS conference committee. In 2022, I
                    was awarded the Outstanding Postdoctoral Associate in the
                    Department of Geographical Sciences, and 2 years later, in
                    2024, I was awarded the Outstanding Research Faculty in the
                    Department of Geographical Sciences.
                  </Text>
                </VStack>

                {/* PROJECTS SECTION */}
                <Box ref={projectsRef}>
                  <Text
                    fontSize={{ base: "16px", lg: "20px" }}
                    color={useColorModeValue("yellow.500", "yellow.300")}
                    fontWeight={"500"}
                    textTransform={"uppercase"}
                    mb={4}
                  >
                    Projects
                  </Text>

                  {/* SECTION 1: Intro / context */}
                  <Box as="section" >
                    <SimpleGrid
                      columns={{ base: 1, md: 2 }}
                      spacing={6}
                      alignItems="start"
                    >
                      <Box>
                        <Image
                          rounded={"md"}
                          alt="Manyeleti Nature Reserve in South Africa by Frik Bronkhorst"
                          src={pic4}
                          fit={"contain"}
                          w={"100%"}
                          h={{ base: "100%", sm: "300px", lg: "400px" }}
                        />
                        <Text
                          color={useColorModeValue("gray.500", "gray.400")}
                          as="cite"
                          display="block"
                          align={"center"}
                          mt={2}
                        >
                          Manyeleti Nature Reserve in South Africa (photo
                          credit: Frik Bronkhorst)
                        </Text>
                      </Box>
                      <Box>
                        <Text>
                          In areas where fire is a dominant ecological
                          disturbance and vital habitat management technique,
                          the long-term fire suppression led to the diminishment
                          of habitats and the buildup of unburned fuels that
                          risk larger, more intense, and harder-to-control
                          wildfires. Specifically, prescribed fires are commonly
                          used in tropical savannas and the southeastern U.S. to
                          reduce fuels, enhance wildlife habitat, favor
                          endangered and threatened species, and restore
                          vegetation structure. Despite widespread application
                          and a number of benefits of those controlled burns,
                          their use remains controversial.
                        </Text>
                        <Text mt={4}>
                          The near absence of prescribed fire statistics due to
                          difficulties in mapping those small low-intensity
                          burns precludes the evaluation of the effectiveness of
                          extensive prescribed fire management for fuel and fire
                          risk reduction, which, if present, would encourage
                          burning and educate the public about the benefits of
                          prescribed fires.
                        </Text>
                      </Box>
                    </SimpleGrid>
                    <Divider my={8} />
                  </Box>

                  {/* SECTION 2: Woody Encroachment */}
                  <Box as="section" ref={projectDetailsRef2}>
                                      <Text
                                          fontSize={{ base: "16px", lg: "20px" }}
                                          color={useColorModeValue("yellow.500", "yellow.300")}
                                          mb={4}
                                          fontWeight={"500"}>
                                          Woody Encroachment and Fire Management
                                      </Text>

                    <SimpleGrid
                      columns={{ base: 1, md: 2 }}
                      spacing={6}
                      alignItems="start"
                    >
                      <Box>
                        <Image
                          rounded={"md"}
                          alt="Manyeleti Nature Reserve in South Africa by Lötter"
                          src={pic1}
                          fit={"contain"}
                          w={"100%"}
                          h={{ base: "100%", sm: "300px", lg: "400px" }}
                        />
                        <Text
                          color={useColorModeValue("gray.500", "gray.400")}
                          as="cite"
                          align={"center"}
                          display="block"
                          mt={2}
                        >
                          Manyeleti Nature Reserve in South Africa (photo
                          credit: Mervyn Lötter)
                        </Text>
                      </Box>
                      <Box>
                        <Text>
                          The study titled “Woody Encroachment in Sub-Saharan
                          Africa: The Cause or the Effect of the Decline in Fire
                          Activity?” funded by NASA Early Career Investigator
                          Program in Earth Science, aims to study the
                          relationships between fire management and woody
                          encroachment in African savannas. The outcomes of this
                          project are essential for guiding climate change
                          adaptation, biodiversity and ecosystem preservation,
                          risk mitigation, poverty alleviation, and community
                          resilience in this ecologically and economically
                          important biome.
                        </Text>
                        <Text mt={4}>
                          Specifically, the project results will inform the
                          debate about whether local management has leverage in
                          controlling woody encroachment in the face of global
                          change. The referenced data produced in collaboration
                          with biodiversity planners from several protected
                          areas in South Africa guided prescribed fires during
                          the 2023 and 2024 seasons{" "}
                          <Link
                            color="blue.500"
                            href="https://doi.org/10.1016/j.jag.2024.104064"
                          >
                            (Zubkova et al., 2024)
                          </Link>
                          . Those fires aimed to preserve the woody-grass
                          equilibrium essential for biodiversity within those
                          natural reserves.
                        </Text>
                      </Box>
                    </SimpleGrid>
                    <Divider my={8} />
                  </Box>

                  {/* SECTION 3: Prescribed Fire Mapping */}
                  <Box as="section" ref={projectDetailsRef3}>
                                      <Text
                                          fontSize={{ base: "16px", lg: "20px" }}
                                          color={useColorModeValue("yellow.500", "yellow.300")}
                                          fontWeight={"500"}
                                          mb={4}>
                                          Prescribed Fire Mapping in Central Florida
                                      </Text>

                    {/**
                      Example of "no image" scenario:
                      We can either do a 1-column SimpleGrid if there truly is no image,
                      or keep a 2-column grid with an empty Box for the left column.
                    */}
                    <SimpleGrid
                      // If you have no image at all, just use columns={{ base:1, md:1 }}
                      // If you want to keep a 2-col layout for future images, do this:
                      columns={{ base: 1, md: 1}}
                      spacing={6}
                      alignItems="start"
                    >
                      <Box>
                        {/* If you truly have no image, remove this <Box> or keep it empty */}
                      </Box>
                      <Box>
                        <Text>
                          Another NASA-funded research that I lead is titled “A
                          decision support system for automated precise
                          prescribed fire mapping in the Everglades Headwaters”.
                          The project funded by the Earth Action: Wildland Fires
                          program will automate prescribed fire mapping in
                          Central Florida through the multi-sensor fusion of
                          NASA, commercial satellite, and drone imagery.
                          Prescribed fires are typically small and low-intensity
                          and, therefore, remain undetected by coarse resolution
                          datasets, the only currently available ready-to-use
                          fire data.
                        </Text>
                        <Text mt={4}>
                          I believe that providing accurate data on prescribed
                          fires can encourage burning and educate the public
                          about their benefits, such as the reduction of the
                          buildup of unburned fuels that risk larger, more
                          intense, and harder-to-control wildfires, enhance
                          wildlife habitat, favor endangered and threatened
                          species, and restore vegetation structure. Therefore,
                          the data that will be developed in collaboration with{" "}
                          <Link
                            color="blue.500"
                            href="https://www.archbold-station.org/"
                          >
                            Archbold Biological Station
                          </Link>{" "}
                          will be used as a foundation to make better
                          recommendations for management in rare and endemic
                          bird species protection and expand our understanding
                          of fire management in rapidly developing areas.
                        </Text>
                      </Box>
                    </SimpleGrid>
                    <Divider my={8} />
                  </Box>

                  {/* SECTION 4: Wildfires and Human Conflict */}
                  <Box as="section" ref={projectDetailsRef4}>
                                      <Text
                                          fontSize={{ base: "16px", lg: "20px" }}
                                          color={useColorModeValue("yellow.500", "yellow.300")}
                                          fontWeight={"500"}
                                          mb={4}>
                                          Wildfires and Human Conflict
                                      </Text>

                    {/* Because you have multiple images, you can break them into multiple SimpleGrids */}
                    <SimpleGrid
                      columns={{ base: 1, md: 2 }}
                      spacing={6}
                      alignItems="start"
                      mb={6}
                    >
                      <Box>
                        <Image
                          rounded={"md"}
                          alt={
                            "Time-Series of the annual burned area in Syria"
                          }
                          src={pic2}
                          fit={"contain"}
                          w={"100%"}
                          h={{ base: "100%", sm: "300px", lg: "400px" }}
                        />
                        <Text
                          color={useColorModeValue("gray.500", "gray.400")}
                          as="cite"
                          display="block"
                          mt={2}
                          align={"center"}
                        >
                          A time series (2002–2020) of the annual burned area
                          accumulated over a calendar year within all 14
                          governorates of Syria.
                        </Text>
                      </Box>
                      <Box>
                        <Text>
                          Apart from developing methods for mapping prescribed
                          fires and assessing their benefits for biodiversity
                          conservation, I focus my research on monitoring abrupt
                          changes in wildfires in developing countries that do
                          not get enough attention from journalists or
                          scientists but where changes in fire behavior can be
                          as detrimental or even more compared to countries with
                          higher fire management and suppression budgets. My
                          past work explored shifts in fire activity as the
                          result of violent conflicts and massive human
                          migration in Syria{" "}
                          <Link
                            color="blue.500"
                            href="https://doi.org/10.1175/EI-D-21-0009.1"
                          >
                            (Zubkova et al., 2021)
                          </Link>{" "}
                          and South Sudan – North Uganda{" "}
                          <Link
                            color="blue.500"
                            href="https://doi.org/10.1088/1748-9326/ac6e48"
                          >
                            (Nakalembe et al., 2022)
                          </Link>
                          , which is currently a grossly underrepresented topic
                          of fire research.
                        </Text>
                        <Text mt={4}>
                          Data gathering is limited in unsafe conflict zones.
                          Remote sensed data can provide unbiased statistics of
                          fire counts and land cover changes that can facilitate
                          policy decisions for humanitarian assistance,
                          distributing targeted aid, or inclusive development
                          programs. Specifically, in Syria, based on NASA MODIS
                          Burned Area Product (MCD64A1), a 2300% increase in
                          fire activity was detected in 2019 compared to
                          2002-2018 conditions. A year later, in 2020, while
                          fire activity was reduced relative to 2019, it still
                          reached an astonishing amount, 10 times above the
                          average.
                        </Text>
                      </Box>
                    </SimpleGrid>

                    <SimpleGrid
                      columns={{ base: 1, md: 2 }}
                      spacing={6}
                      alignItems="start"
                    >
                      <Box>
                        <Image
                          rounded={"md"}
                          alt={"Fire Activity in Syria"}
                          src={pic3}
                          fit={"contain"}
                          w={"100%"}
                          h={{ base: "100%", sm: "300px", lg: "400px" }}
                        />
                        <Text
                          color={useColorModeValue("gray.500", "gray.400")}
                          as="cite"
                          display="block"
                          mt={2}
                          align={"center"}
                        >
                          Fire Activity in Syria
                        </Text>
                      </Box>
                      <Box>
                        <Text>
                          Abnormally high rainfall during winter-spring 2019
                          explained close to two-thirds of the drastic increase
                          in the extent of the burned area in the northern part
                          of Syria. In 2020, fire activity moved to the west,
                          away from wheat and barley’s major production, to
                          government- or opposition-controlled territory.
                          Above-normal temperatures were recorded at the end of
                          the 2020 fire season, especially in the northwest part
                          of the country, which was partially responsible for
                          the increase in fire activity in more biomass-rich
                          ecosystems.
                        </Text>
                        <Text mt={4}>
                          Heavy rainfall events, while rare, were observed in
                          the previous year, but Syria remained a nearly
                          fire-free country before the summer of 2019. This
                          study confirmed that a drastic change in fire activity
                          is usually a result of a combination of climatic and
                          anthropogenic factors, in this case, fire-favorable
                          weather conditions, poor emergency response due to the
                          war, and maliciously started fires by the Islamic
                          State, pro-government militias, or opposition rebels
                          created an abundance of highly combustible fuel
                          together with a plenitude of ignition sources.
                        </Text>
                      </Box>
                    </SimpleGrid>
                    <Divider my={8} />
                  </Box>
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Flex>
        {/* 
          Floating "Back to Menu" button:
          Position: fixed so it doesn't move while scrolling,
          bottom + right to pin it at the corner.
        */}
        <Box
          position="fixed"
          bottom={4}
          right={4}
          zIndex={10} // ensure it floats above other content
        >
          <IconButton
            color="blue.500"
            onClick={() => scrollToSection(menuRef)}
            boxShadow="md"
            isRound={true}
            icon={<FaChevronUp />}
          />

        </Box>
      </Box>
    </ChakraProvider>
  );
}
